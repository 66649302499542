import { TableType } from 'components/table/Table'

export interface Info {
  address?: string
  website?: string
  email?: string
  telephone?: string
  fax?: string
  facebook?: string
  twitter?: string
  instagram?: string
  ceo?: string
  president?: string
  contact?: string
  ticketshop?: string
  youtube?: string
  tikTok?: string
  hygieneConcept?: string
  waxAllowed?: boolean
  waxInfo?: string
  fieldNumber?: string
}

export interface OrganizationInfo extends Info {
  tournamentsCount: number
  clubsCount: number
}

export interface FacetValue {
  id: string
  name: string
  selected: boolean
}

export interface FacetValueGroup {
  id: string
  name: string
  values: FacetValue[]
}

export interface Facet {
  key: string
  values: (FacetValue | FacetValueGroup)[]
  removable: boolean
  clearOnChange?: string[]
}

export interface ListMeta {
  totalCount: number
  perPage: number
  page: number
  pageCount: number
  facets: Facet[]
}

export interface PaginatedResponse<T> {
  data: T[]
  meta: ListMeta
}

export interface Organization {
  id: string
  name: string
  acronym?: string
  logo?: string
  sort: number
  organizationType?: string
}

export interface Club {
  id: string
  name: string
  organization?: Organization
  info: Info
  teams?: Team[]
  schedule: Schedule
  logo?: string
  livestreamUrl?: string
  hasMemberClubs?: boolean
  hasParentClubs?: boolean
}

export interface ClubForMap {
  id: string
  name: string
  acronym?: string
  logo?: string
  street?: string
  postalCode?: string
  city?: string
  website?: string
  lat: number
  lng: number
  viewport: string
}

enum TournamentType {
  league = 'League',
  tournament = 'Competition'
}

export enum AgeGroup {
  men = 'Men',
  women = 'Women',
  aYouth = 'AYouth',
  bYouth = 'BYouth',
  cYouth = 'CYouth',
  dYouth = 'DYouth',
  eYouth = 'EYouth',
  fYouth = 'FYouth',
  minis = 'Minis'
}

export interface Tournament {
  tournamentType: TournamentType
  id: string
  name: string
  organization?: Organization
  rounds?: Round[]
  weeks?: Week[]
  logo?: string
  livestreamUrl?: string
  tableHint?: string
  ageGroup?: AgeGroup
}

export interface TournamentSummary {
  acronym: string
  currentRound?: Round
  id: string
  logo?: string
  name: string
  organization?: Organization
  table?: TournamentTable
  tournamentType: TournamentType
}

// TBD: do we really needs this at this level?
// interface TournamentGroup {
//   tournament: Tournament
// }

export interface Phase {
  id: string
  acronym: string
  name: string
}

export interface Round {
  id: string
  acronym: string
  name: string
  tournament?: Tournament
  games?: Game[]
  startsAt?: number
  endsAt?: number
}

// Playing Calendar Weeks for grouping
export interface Week {
  name: string
  startsAt: Date
  endsAt: Date
}

export interface Team {
  id: string
  name: string
  acronym?: string
  teamGroupId?: number

  // club&clubs is probably not final
  club: Club
  clubs?: Club[]
  logo?: string
  schedule: Schedule
  squad: Squad
  defaultTournament?: Tournament
}

export enum GameState {
  PRE = 'Pre',
  LIVE = 'Live',
  POST = 'Post',
  POSTPONED = 'Postponed',
  CANCELED = 'Canceled'
}

// Übersetzungen ?
export enum GameExtraState {
  CUSTOM = 'Custom',
  WO_HOME = 'WoHome',
  WO_AWAY = 'WoAway',
  WO_BOTH = 'WoBoth',
  NOT_PARTICIPATED_HOME = 'NotParticipatedHome',
  NOT_PARTICIPATED_AWAY = 'NotParticipatedAway',
  NOT_PARTICIPATED_BOTH = 'NotParticipatedBoth',
  REVALUATION = 'Revaluation',
  PROTEST_HOME = 'ProtestHome',
  PROTEST_AWAY = 'ProtestAway',
  RELEASED_HOME_TEAM = 'ReleasedHomeTeam',
  RELEASED_AWAY_TEAM = 'ReleasedAwayTeam'
}

declare interface AbstractGame {
  id: string
  homeTeam: Team
  awayTeam: Team
  tournament: Tournament
  phase: Phase
  round: Round
  field?: Field
  startsAt: number // Date does not work properly for next.js-serialization
  showTime: boolean
  homeGoals: number | null
  awayGoals: number | null
  homeGoalsHalf: number | null
  awayGoalsHalf: number | null
  extraStates: GameExtraState[] | null
  refereeInfo?: string
  remarks: string
  pdfUrl?: string
  attendance?: number
  tickerRef?: string
  livestreamUrl?: string
  customUrl?: string
  customUrlLabel?: string
  showPresstext: boolean
  gameNumber?: string
  meta?: string
  updatedAt: number // Date does not work properly for next.js-serialization
}

export interface CombinedGame {
  summary: Game
  lineup: GameLineup
  events: GameEvent[]
}

export interface PreGame extends AbstractGame {
  state: GameState.PRE
}

export interface LiveGame extends AbstractGame {
  state: GameState.LIVE
}

export interface PostGame extends AbstractGame {
  state: GameState.POST
}

export interface PostponedGame extends AbstractGame {
  state: GameState.POSTPONED
}

export interface CanceledGame extends AbstractGame {
  state: GameState.CANCELED
}

export interface GamePlayer {
  id: string
  firstname: string
  lastname: string
  position: string
  number: number
  type: 'Player'
  goals: number | null
  penaltyGoals: number | null
  penaltyMissed: number | null

  penalties: number | null
  redCards: number | null
  yellowCards: number | null
  blueCards: number | null
}

export interface GameStaffMember {
  id: string
  firstname: string
  lastname: string
  position: string
  type: 'StaffMember'
  timePenalties: number | null
  warnings: number | null
  disqualifications: number | null
  disqualificationWithBlueCards: number | null
}

export interface GameReferee {
  id: string
  firstname: string
  lastname: string
  position: string
  type: 'Referee'
}

export interface GameEvent {
  id: string
  type: string
  time: string
  score: string
  timestamp: number
  team: 'Home' | 'Away' | null
  message: string
}

export type Game = PreGame | LiveGame | PostGame | PostponedGame | CanceledGame

export interface GameLineup {
  home: GamePlayer[]
  homeOfficials: GameStaffMember[]
  away: GamePlayer[]
  awayOfficials: GameStaffMember[]
  referees: GameReferee[]
}

export interface GamePresstext {
  presstext: string
  updatedAt: number
}

export type Schedule = Game[]

export interface TournamentSchedule extends Schedule {
  tournament: Tournament
}

export interface TournamentRoundSchedule extends TournamentSchedule {
  round: Round
}

// warning: rank (or other fields) are not valid for client-side sort (might have duplicates!)
export interface TournamentTable {
  tournament: Tournament
  header: string
  footer: string
  tableType: TableType
  rows: {
    rank: number
    team: Team
    points: string // TODO : split (xx:yy) ?
    games: number
    wins: number
    draws: number
    losses: number
    goals: number
    goalsAgainst: number
    goalDifference: number
    promoted: boolean
    relegated: boolean
  }[]
}

export interface TournamentCrossTable {
  tournament: Tournament
  columns: Team[]
  tableType: TableType
  rows: {
    team: Team
    columns: (Game | null)[]
  }[]
}

export interface TournamentEventStatistics {
  goals: number | null
  sevenMeters: number | null
  sevenMeterGoals: number | null
  redCards: number | null
  yellowCards: number | null
  blueCards: number | null
  suspensions: number | null
  fieldGoals: number | null
  blocks: number | null
  saves: number | null
  steals: number | null
  technicalFouls: number | null
  assists: number | null
  goalsConceded: number | null
}

export interface TournamentStatistics {
  games: number | null
  gamesPlayed: number | null
  winsHome: number | null
  draws: number | null
  winsAway: number | null
  eventStatistics: TournamentEventStatistics
}

export interface TournamentStatisticsData {
  meta: ListMeta
  data: TournamentStatistics
}

export interface TournamentStatisticsTeamRow {
  rank: number
  team: Team | null
  gamesPlayed: number | null
  eventStatistics: TournamentEventStatistics
}

export interface TournamentStatisticsPlayerRow {
  rank: number
  team: Team | null
  firstname: string | null
  lastname: string | null
  gamesPlayed: number | null
  eventStatistics: TournamentEventStatistics
}

export interface TeamSchedule {
  team: Team
  games: Game[]
}

export interface Tag {
  name: string
  slug: string
  logo: string
}

export interface News {
  id: string
  slug: string
  title: string
  subtitle: string
  feature_image?: string
  feature_image_alt?: string | null
  excerpt: string
  plaintext: string
  html: string
  published_at: number
  authors: Author[]
  organization?: Organization
  club?: Club
  tags: Tag[]
  primary_tag: Tag
  meta_description?: string | null
  meta_title?: string | null
  og_description?: string | null
  og_image?: string | null
  og_title?: string | null
  twitter_description?: string | null
  twitter_image?: string | null
  twitter_title?: string | null
}

export interface Author {
  id: string
  name: string
}

export interface Coach {
  id: string
  image: string
  firstname: string
  lastname: string
  // Next.js issue with serializing
  birthdate?: number
  position?: string
}

// Remove?
export interface PlayerTeamStatistic {
  appearances: number | null
  goals: number | null
}

// Remove?
export interface PlayerStatistics {
  current_team: PlayerTeamStatistic | null
}

export interface Player {
  id: string
  image: string
  firstname: string
  lastname: string
  // Next.js issue with serializing
  birthdate?: number
  position?: string
  nationality?: string
  statistics?: PlayerStatistics
}

export interface SquadPosition {
  id: string
  people: (Coach | Player)[]
  position: string
  name: string
}

export interface Squad {
  id: string
  name: string
  portrait?: string
  positions: SquadPosition[]
}

export interface Field {
  id: string
  name: string
  acronym?: string
  city: string
  club?: Club
  info: Info
  fieldNumber?: string
}

export type SummaryType =
  | 'ClubSummary'
  | 'FieldSummary'
  | 'GameSummary'
  | 'OrganizationSummary'
  | 'PhaseSummary'
  | 'RoundSummary'
  | 'TeamSummary'
  | 'TournamentSummary'

export type SearchResultType =
  | 'FieldSummary'
  | 'TournamentSummary'
  | 'ClubSummary'
  | 'TeamSummary'

export type SearchType = 'tournaments' | 'clubs' | 'fields' // | 'teams'

export interface SearchResult {
  organization?: Organization
  defaultTournament?: Tournament
  city?: string
  id: string
  name: string
  logo?: string
  type: SearchResultType
}

export interface TeamSummary {
  acronym: string
  club: Club
  defaultTournament: Tournament
  id: string
  teamGroupId?: number
  logo?: string
  name: string
  nextGame: Game | null
  previousGame: Game | null
  type: SummaryType
}

export interface UserProfile {
  userId: string
  firstname: string
  lastname: string
  email: string
  favClubs?: Club[]
  favOrgas?: Organization[]
  favTeams?: Team[]
  birthDate?: string // date string
  currentClub?: string
  organization?: string
  favoriteNationalPlayer?: string
  roles?: string[]
  commercialPurposesAccepted?: boolean
  favoritePlayer?: string
  clothingSize?: string
}

export interface CreateTickerResponse {
  localId: string
}

export interface TickarooTickerListEntry {
  tickarooId: string
  tickarooLocalId: string
  userId: number
  username: string
}

export interface TickarooLoginResponse {
  id: string
  accessToken: string
}

export type AdType = 'HOME' | 'REST'
