import(/* webpackMode: "eager", webpackExports: ["HalfPageAd"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/HalfPageAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeaderBoardAd"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/LeaderBoardAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OtherAd"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/OtherAd.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/RectangleAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentGames"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/home/CurrentGames.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/home/FanqWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/home/FlocklerWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyTeamSummary"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/home/MyTeamSummary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhyRegister"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/home/WhyRegister.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/layout/ContentHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/news/NewsWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/videocenter/VideocenterPreview.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/node_modules/next/dist/client/link.js");
